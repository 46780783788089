import { useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom'
import { Container, Row, Col, Button, Tab, Tabs, Form } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import axios from '../../../axios'

import TrackerAirFreight from './../../../assets/images/tracker-air-freight.png'
import TrackerMaritime from './../../../assets/images/tracker-maritime-tracker.png'

import SectionConnectBg1 from './../../../assets/images/section-2-bg-1.svg'

const Product = () => {
    const dispatch = useDispatch()

    const [Products, setProducts] = useState([])
    const [CartItems, setCartItems] = useState([])
    const [ProductDetailsIndex, setProductDetailsIndex] = useState(0)

    const Quantity = Array.from({ length: 100 }).map((_, i) => { return { value: i + 1, label: (i + 1).toString().padStart(2, 0) } })
    const maxQuantityAllowed = 100

    const [AirFreightTrackerQuantity, setAirFreightTrackerQuantity] = useState(1)
    const [MaritimeTrackerQuantity, setMaritimeTrackerQuantity] = useState(1)

    useEffect(() => {
        getProducts()
    }, [])

    const getProducts = () => {
        dispatch({ type: 'set', loader: true })
        axios.get('public/products/get')
            .then(res => {
                dispatch({ type: 'set', loader: false })
                setProducts(res?.data?.data || [])
                getCartItems()
            })
            .catch(err => {
                dispatch({ type: 'set', loader: false })
                console.log(err)
            })
    }

    const getCartItems = () => {
        let carts = localStorage.getItem('carts')
        if (carts) {
            setCartItems(JSON.parse(carts))
        }
    }

    const addToCart = (productName, quantity) => {
        let product = Products.find(prod => prod.name === productName)
        if (product) {
            let prevItems = [...CartItems]
            let newItems = []
            let itemRefIndex = prevItems.findIndex(item => item.product === product._id)
            if (itemRefIndex > -1) {
                let newQuantity = prevItems[itemRefIndex].quantity + +quantity
                if (newQuantity > maxQuantityAllowed) {
                    newQuantity = maxQuantityAllowed
                    // TODO: Show max quantity allowed message
                }
                prevItems[itemRefIndex].quantity = newQuantity
                newItems = [...prevItems]
            } else {
                let newQuantity = +quantity
                if (newQuantity > maxQuantityAllowed) {
                    newQuantity = maxQuantityAllowed
                    // TODO: Show max quantity allowed message
                }
                newItems = [...prevItems, { product: product._id, quantity: newQuantity }]
            }
            setCartItems(newItems)
            localStorage.setItem('carts', JSON.stringify(newItems))
            dispatch({ type: 'set', cart: newItems })
            toast.success(`${quantity} ${productName} added to cart.`)
        } else {
            toast.error(`${productName} not found.`)
        }
    }

    const plans = {
        'air-freight': [
            {
                price: 99,
                name: 'Basic',
                type: 'Small Customer',
                device: '14'
            },
            {
                price: 299,
                name: 'Standard',
                type: 'Mid Customer',
                device: '25'
            },
            {
                price: 399,
                name: 'Enterprise',
                type: 'Large Customer',
                device: '50+'
            }
        ],
        'maritime': [
            {
                price: 399,
                name: 'Basic',
                type: 'Small Customer',
                device: '14'
            },
            {
                price: 499,
                name: 'Standard',
                type: 'Mid Customer',
                device: '25'
            },
            {
                price: 599,
                name: 'Enterprise',
                type: 'Large Customer',
                device: '50+'
            }
        ]
    }

    return (
        <>
            <section className="page-banner product-section-1">
                <Container>
                    <Row>
                        <Col md={12} className="text-center mb-4 pb-4">
                            <div className="text-large">
                                Products
                            </div>
                            <div className="text-small">
                                Explore our suite of innovative products - the breakthroughs in
                                <br />supply chain management.
                            </div>
                        </Col>
                    </Row>
                </Container>

                <Container className='border-dashed'>
                    <Row>
                        <Col md={6}>
                            <div className="text-large">
                                The GTAI
                                <br />Maritime Tracker
                            </div>
                            <div className="text-small text-justify pt-4">
                                Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at. Purus est sapien vitae vulputate. Facilisis lorem sit lacus enim. Vitae morbi quam felis libero in in vitae proin varius. Diam leo eu nibh rhoncus elementum gravida. Lorem ut sit risus purus suscipit. Amet vestibulum enim sed nisl leo diam.
                            </div>
                            {/* <div className="d-flex align-items-center gap-4 mt-4 pt-4">
                                <Form.Select
                                    className="quantity"
                                    value={MaritimeTrackerQuantity}
                                    onChange={(e) => setMaritimeTrackerQuantity(e.target.value)}
                                >
                                    {
                                        Quantity.map(((quant, i) => {
                                            return <option key={i} value={quant.value}>{quant.label}</option>
                                        }))
                                    }
                                </Form.Select>
                                <Button variant="success" onClick={() => addToCart('Maritime Tracker', MaritimeTrackerQuantity)}>Add To Cart</Button>
                                <NavLink to="/products/checkout">
                                    <Button variant="primary">Checkout</Button>
                                </NavLink>
                            </div> */}
                            {
                                ProductDetailsIndex === 1
                                    ? <div className='more' onClick={() => setProductDetailsIndex(0)}>Less <span>&gt;</span></div>
                                    : <div className='more' onClick={() => setProductDetailsIndex(1)}>More <span>&gt;</span></div>
                            }
                        </Col>
                        <Col md={6}>
                            <img className="bg-1" src={TrackerAirFreight} alt="Bg 1" width="100%" />
                        </Col>
                    </Row>
                    {
                        ProductDetailsIndex === 1 && (
                            <Row className='mt-4 pt-4'>
                                <Col md={6}>
                                    <div className="description-title">
                                        Sensor Specifications
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TEMPERATURE MONITORING:
                                        </div>
                                        <ul>
                                            <li>
                                                Precise control from -40 C to 125 C.
                                            </li>
                                            <li>
                                                Deal for sensitive and perishable cargo.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TRACKING
                                        </div>
                                        <ul>
                                            <li>
                                                Monitors 0% to 100% humidity levels
                                            </li>
                                            <li>
                                                Preserves product quality during transit.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TEMPERATURE MONITORING:
                                        </div>
                                        <ul>
                                            <li>
                                                Precise control from -40 C to 125 C.
                                            </li>
                                            <li>
                                                Deal for sensitive and perishable cargo.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            LIGHT DETECTION:
                                        </div>
                                        <ul>
                                            <li>
                                                Monitors 0% to 100% humidity levels.
                                            </li>
                                            <li>
                                                Preserves product quality during transit.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            MOTION/ACCELERATION SENSING:
                                        </div>
                                        <ul>
                                            <li>
                                                Monitors 0% to 100% humidity levels.
                                            </li>
                                            <li>
                                                Preserves product quality during transit.
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="description-title">
                                        Key Features
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TEMPERATURE MONITORING:
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at.
                                        </div>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            REAL-TIME VISIBILITY
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at.
                                        </div>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            COMPACT DESIGN
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                </Container>

                <Container className='border-dashed pt-4' style={{ marginTop: 60 }}>
                    <Row>
                        <Col md={5}>
                            <img className="bg-1" src={TrackerMaritime} alt="Bg 1" width="100%" />
                        </Col>
                        <Col md={1}></Col>
                        <Col md={6}>
                            <div className="text-large">
                                The GTAI Air
                                <br />Freight Tracker
                            </div>
                            <div className="text-small text-justify pt-4">
                                Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at. Purus est sapien vitae vulputate. Facilisis lorem sit lacus enim. Vitae morbi quam felis libero in in vitae proin varius. Diam leo eu nibh rhoncus elementum gravida. Lorem ut sit risus purus suscipit. Amet vestibulum enim sed nisl leo diam.
                            </div>
                            {/* <div className="d-flex align-items-center gap-4 mt-4 pt-4">
                                <Form.Select
                                    className="quantity"
                                    value={AirFreightTrackerQuantity}
                                    onChange={(e) => setAirFreightTrackerQuantity(e.target.value)}
                                >
                                    {
                                        Quantity.map(((quant, i) => {
                                            return <option key={i} value={quant.value}>{quant.label}</option>
                                        }))
                                    }
                                </Form.Select>
                                <Button variant="success" onClick={() => addToCart('Air Freight Tracker', AirFreightTrackerQuantity)}>Add To Cart</Button>
                                <NavLink to="/products/checkout">
                                    <Button variant="primary">Checkout</Button>
                                </NavLink>
                            </div> */}
                            {
                                ProductDetailsIndex === 2
                                    ? <div className='more' onClick={() => setProductDetailsIndex(0)}>Less <span>&gt;</span></div>
                                    : <div className='more' onClick={() => setProductDetailsIndex(2)}>More <span>&gt;</span></div>
                            }
                        </Col>
                    </Row>
                    {
                        ProductDetailsIndex === 2 && (
                            <Row className='mt-4 pt-4'>
                                <Col md={6}>
                                    <div className="description-title">
                                        Sensor Specifications
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TEMPERATURE MONITORING:
                                        </div>
                                        <ul>
                                            <li>
                                                Precise control from -40 C to 125 C.
                                            </li>
                                            <li>
                                                Deal for sensitive and perishable cargo.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TRACKING
                                        </div>
                                        <ul>
                                            <li>
                                                Monitors 0% to 100% humidity levels
                                            </li>
                                            <li>
                                                Preserves product quality during transit.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TEMPERATURE MONITORING:
                                        </div>
                                        <ul>
                                            <li>
                                                Precise control from -40 C to 125 C.
                                            </li>
                                            <li>
                                                Deal for sensitive and perishable cargo.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            LIGHT DETECTION:
                                        </div>
                                        <ul>
                                            <li>
                                                Monitors 0% to 100% humidity levels.
                                            </li>
                                            <li>
                                                Preserves product quality during transit.
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            MOTION/ACCELERATION SENSING:
                                        </div>
                                        <ul>
                                            <li>
                                                Monitors 0% to 100% humidity levels.
                                            </li>
                                            <li>
                                                Preserves product quality during transit.
                                            </li>
                                        </ul>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="description-title">
                                        Key Features
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            TEMPERATURE MONITORING:
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at.
                                        </div>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            REAL-TIME VISIBILITY
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at.
                                        </div>
                                    </div>
                                    <div className="text-small pt-4">
                                        <div className='small-title'>
                                            COMPACT DESIGN
                                        </div>
                                        <div>
                                            Lorem ipsum dolor sit amet consectetur. Lectus convallis ullamcorper facilisi ac quam semper dui vel at.
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }
                </Container>
            </section >


            <section className="about-section-3">
                <Container>
                    <div className="text-center text-large">
                        We lead the evolution of product tracking
                        <br /> with secure, smart solutions.
                    </div>
                    <div className="text-center mt-4 pt-4">
                        <NavLink to="/contact">
                            <Button variant="success"> Request A Demo </Button>
                        </NavLink>
                    </div>
                    <img className="bg-1" src={SectionConnectBg1} alt="Bg 1" />
                </Container>
            </section>
        </>
    )
}

export default Product